<template>
  <div>
    <div v-for="(vehicle, index) in vehicleList" :key="index">
      <span :id="`${id}-actionable-column-href-`">
        {{ trackingStatus }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    rowProps: {
      type: Object,
      default: () => ({}),
    },
    column: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      default: null,
    },
  },
  computed: {
    vehicleList() {
      if (this.row[this.column.prop]) {
        return this.row[this.column.prop].split('|')
      }
      return []
    },
    trackingStatus() {
      if (this.vehicleList.length === 0) {
        return ''
      }
      const trackedVehicleCount = this.vehicleList.reduce(
        (c, vehicle) => c + (vehicle.split(',')[1] === 'tracked'),
        0
      )

      let trackingStatus = 'Not Tracked'

      if (trackedVehicleCount == 0) {
        trackingStatus = 'Not Tracked'
      } else if (trackedVehicleCount < this.vehicleList.length) {
        trackingStatus = 'Partially Tracked'
      } else {
        trackingStatus = 'Tracked'
      }

      return `${trackingStatus} (${trackedVehicleCount}/${this.vehicleList.length})`
    },
  },
}
</script>

<template>
  <CRSelect
    :id="`${id}-dot-status-type-filter-select-operator-tier`"
    v-model="operatorTierLabelSelections"
    :items="operatorTierLabels"
    chips
    flat
    hide-details
    multiple
    solo
    @input="setFilter"
  />
</template>

<script>
import { deepClone } from '@/utils/deepClone'

export default {
  props: {
    filters: {
      type: Object,
      default: () => undefined,
    },
    control: {
      type: Object,
      default: () => undefined,
    },
    column: {
      type: Object,
      default: () => undefined,
    },
    addFilter: {
      type: Function,
      default: () => () => true,
    },
    id: {
      type: String,
      default: null,
    },
    setReferralFilter: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      operatorTierLabels: [
    {
      text: 'Tier 1',
      value: 'Tier_1',
    },
    {
      text: 'Tier 2',
      value: 'Tier_2',
    },
    {
      text: 'Tier 3',
      value: 'Tier_3',
    },
    {
      text: 'No tier',
      value: 'No_tier',
    }
  ],
      operatorTierLabelSelections: [],
      filter: {
        column: {
          _t_id: 'd7621ad4-8b27-4a06-98a8-f014beeb30f8',
          prop: 'referralOperatorTierLabels',
          filterType: 'contains',
        },
        activeFilter: undefined,
        hideOnFilterBar: true,
      },
    }
  },
  async mounted() {
    const activeFilter = this.findFilter(this.filter)
    if (activeFilter) {
      this.activeFilter = activeFilter
      this.$nextTick(() => {
        const rebuiltSelections = activeFilter.value
        if (!!rebuiltSelections) {
          this.operatorTierLabelSelections = rebuiltSelections
            .split(' ')
          this.addFilter()
        }
      })
    }
  },
  methods: {
    findFilter(filter) {
      return filter && this.filters.find(filter)
    },
    setFilter() {
      this.filters.remove(this.filter)

      if (this.operatorTierLabelSelections.length) {
        const grandParent = this.filters.createParent('and')
        this.grandParentFilter = grandParent
        const newFilter = deepClone(this.filter)
        newFilter.value = this.operatorTierLabelSelections.join(' ')
        newFilter.stepParent = this.column?._t_id
        this.filters.add(this.grandParentFilter, newFilter)
      }

      this.$emit('make-dirty-request')
      this.addFilter()
      this.setReferralFilter(this.operatorTierLabelSelections)
    },
  },
}
</script>
